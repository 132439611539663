<template>
    <el-submenu :index="name" v-if="child">
        <template slot="title">
            <i :class="icon"></i>
            <span style="padding-left:15px">{{label}}</span>
        </template>
        <sidebarItemChild v-for="(subChild,index) in child" :key="index" :label="subChild.label" :icon="subChild.icon" :name="subChild.name" :child="subChild.child" />
    </el-submenu>
    <el-menu-item :index="name" v-else>
        <router-link :to="{name:name}" style="text-decoration:none" v-if="isCollapse">
           <i :class="icon"></i>
        </router-link>
        <span>
            <router-link :to="{name:name}" style="text-decoration:none">
                <i :class="icon"></i>
                <span style="padding-left:15px">{{label}}</span>
            </router-link>
            <!-- {{data.label}} -->
        </span>
    </el-menu-item>
</template>

<script>
import sidebarItemChild from './sidebarItemChild'
export default {
    components:{sidebarItemChild},
    props: {
        label: String,
        name: String,
        icon: String,
        child: Array,
        isCollapse:Boolean
    },
    data() {
        return {}
    },
    created() {

    },
    methods: {}
}
</script>

<style scoped>
.router-link-exact-active {
    color: red;
}
a {
    color: black;
}
.el-menu-item i {
    color: black;
    font-size: 16px;
}
.el-submenu__title i {
    color: black;
    font-size: 16px;
}
</style>
