<template>
<div>
    <div>
        <div v-if="child">
            <el-popover placement="right" width="200" trigger="click">
                <div>
                    <popoveritems v-for="(data,index) in child" :key="index" :label="data.label" :name="data.name" :icon="data.icon" :child="data.child" />
                </div>
                <div slot="reference" class="d-flex justify-content-between hoverClass">
                    <div>
                        <b-icon :icon="icon"></b-icon>
                        <span style="padding-left:15px">{{label}}</span>
                    </div>
                    <div>
                        <b-icon icon="chevron-down"></b-icon>
                    </div>
                </div>
            </el-popover>
            <!-- <b-icon v-if="child" style="float:right" icon="chevron-down" /> -->
        </div>
        <div v-else class="hoverClass">
            <router-link  :to="name" style="color:black;border-bottom:0px;text-decoration:none" :underline="false">
                <b-icon :icon="icon"></b-icon>
                <span style="padding-left:15px">{{label}}</span>
            </router-link>

        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'popoveritems',
    props: {
        label: String,
        name: String,
        icon: String,
        child: Array,
    },
    data() {
        return {
            toggleChild: false,
        }
    },
    created() {

    },
    methods: {
        toggleChildFun() {
            this.toggleChild = !this.toggleChild
        }
    }
}
</script>

<style scoped>
.hoverClass:hover {
    cursor: pointer;
    background: #dee2e6;
}

.hoverClass {
    padding: 10px;
    height: 40px;

}
</style>
