import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/index'
import Cookies from 'js-cookie'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    meta :{
      public:true
    },
    component: () => import('@/views/login/index')
  },
  {
    path: '/dashboard',
    component: Layout,
    children: 
    [
      {
        path: '/dashboard',
        component: () =>
        import ('@/views/dashboard/index'),
        name: 'dashboard',
        meta: { title: 'Dashboard' }
      },
      {
        path: '/dashboard/quantity-wise-customers',
        component: () =>
        import ('@/views/dashboard/quantity-wise-customers'),
        name: 'quantityWiseCustomers',
        meta: { title: 'Quantity Wise Customers' }
      },
      {
        path: '/dashboard/quantity-wise-products',
        component: () =>
        import ('@/views/dashboard/quantity-wise-products'),
        name: 'quantityWiseProducts',
        meta: { title: 'Quantity Wise Products' }
      }
    ]
  },
  {
    path: '/customer',
    component: Layout,
    children: 
    [
      {
        path: ':tid/subscriptions',
        component: () =>
        import ('@/views/customers-data/customer-subscriptions'),
        name: 'customerSubscriptions',
        params:{'tid':':tid'},
        meta: { title: 'Customer Subscriptions' }
      },
      {
        path: ':tid/subscriptions/details',
        component: () =>
        import ('@/views/customers-data/customer-subscriptions-details'),
        name: 'customerSubscriptionsDetails',
        params:{'tid':':tid'},
        meta: { title: 'Customer Subscriptions Details' }
      },
      {
        path: ':tid/subscription-customer-details',
        component: () =>
        import ('@/views/customers-data/subscription-customer-details'),
        name: 'subscriptionCustomerDetails',
        params:{'tid':':tid'},
        meta: { title: 'Customer Details' }
      },
      {
        path: ':tid/settings',
        component: () =>
        import ('@/views/customers-data/settings/index'),
        name: 'customerSetting',
        params:{'tid':':tid'},
        meta: { title: 'Customer Setting' }
      },
      {
        path: ':tid/ingram-orders',
        component: () =>
        import ('@/views/customers-data/ingramOrders/index'),
        name: 'customerIngramOrders',
        params:{'tid':':tid'},
        meta: { title: 'Customer Ingram Orders' }
      },
      {
        path: ':tid/profile',
        component: () =>
        import ('@/views/customers-data/profile/index'),
        name: 'customerProfile',
        params:{'tid':':tid'},
        meta: { title: 'Customer Profile' }
      },
      // {
      //   path: ':tid/transactions',
      //   component: () =>
      //   import ('@/views/customers-data/transaction.vue'),
      //   name: 'customerTransaction',
      //   params:{'tid':':tid'},
      //   meta: { title: 'Customer Transactions' }
      // },
      {
        path: ':tid/purchase',
        component: () =>
        import ('@/views/customers-data/purchaseNew/index.vue'),
        name: 'Purchases',
        params:{'tid':':tid'},
        meta: { title: 'Purchases' }
      },
      // {
      //   path: ':tid/aispl-purchase',
      //   component: () =>
      //   import ('@/views/customers-data/aisplPurchase/index.vue'),
      //   name: 'customerAisplPurchase',
      //   params:{'tid':':tid'},
      //   meta: { title: 'Aispl Purchase' }
      // },
      {
        path: ':tid/order-history',
        component: () =>
        import ('@/views/customers-data/aisplOrderHistory/index.vue'),
        name: 'OrderHistory',
        params:{'tid':':tid'},
        meta: { title: 'Order History' }
      },
      {
        path: ':tid/cart',
        component: () =>
        import ('@/views/customers-data/cart/index.vue'),
        name: 'customerPurchaseCart',
        params:{'tid':':tid'},
        meta: { title: 'Customer Purchase Cart' }
      },

      {
        path: ':tid/cart/buy',
        component: () =>
        import ('@/views/customers-data/cart/buy.vue'),
        name: 'customerPurchaseCartBuy',
        params:{'tid':':tid'},
        meta: { title: 'Customer Purchase Cart Buy' }
      },
      {
        path: ':tid/cart/buy/checkout',
        component: () =>
        import ('@/views/customers-data/cart/checkout1'),
        name: 'customerPurchaseCartBuyCheckOut',
        params:{'tid':':tid'},
        meta: { title: 'Customer Purchase Cart Checkout' }
      },
      {
        path: ':tid/aispl/cart',
        component: () =>
        import ('@/views/customers-data/aisplCart/index.vue'),
        name: 'customerAisplPurchaseCart',
        params:{'tid':':tid'},
        meta: { title: 'Customer Purchase Cart' }
      },
      {
        path: ':tid/aispl/cart/buy',
        component: () =>
        import ('@/views/customers-data/aisplCart/buy.vue'),
        name: 'customerAisplPurchaseCartBuy',
        params:{'tid':':tid'},
        meta: { title: 'Customer Purchase Cart Buy' }
      },
      {
        path: ':tid/aispl/cart/buy/checkout',
        component: () =>
        import ('@/views/customers-data/aisplCart/checkout1'),
        name: 'customerAisplPurchaseCartBuyCheckOut',
        params:{'tid':':tid'},
        meta: { title: 'Customer Purchase Cart Checkout' }
      },
    ]
  },
  {
    path: '/all-customer',
    component: Layout,
    children: 
    [
      {
        path: 'list',
        component: () =>
        import ('@/views/allCustomers/list'),
        name: 'allCustomers',
        meta: { title: 'Total Customers' }
      }
    ]
  },
  {
    path: '/all-subscriptions',
    component: Layout,
    children: 
    [
      {
        path: 'list',
        component: () =>
        import ('@/views/allCustomers/totalSubscriptions'),
        name: 'allSubscriptions',
        meta: { title: 'Total Subscriptions' }
      }
    ]
  },
  {
    path: '/ingram',
    component: Layout,
    children: 
    [
      {
        path: 'dashboard',
        component: () =>
          import ('@/views/ingram/dashboard'),
        name: 'ingramDashboard',
        meta: { title: 'Ingram Dashboard',value:'ingramDashboard' }
      },
      {
        path: 'customers',
        component: () =>
          import ('@/views/ingram/customerList'),
        name: 'ingramCustomer',
        meta: { title: 'Ingram Customers',value:'ingramCustomer' }
      },
      {
        path: 'subscription',
        component: () =>
          import ('@/views/ingram/subscriptionList'),
        name: 'ingramSubscription',
        query:{'vendor':':vendor'},
        meta: { title: 'Ingram Subscriptions',value:'ingramSubscription' }
      }
    ]
  },
  {
    path: '/aispl',
    component: Layout,
    children: 
    [
      {
        path: 'dashboard',
        component: () =>
          import ('@/views/aispl/dashboard'),
        name: 'aisplDashboard',
        meta: { title: 'Aispl Dashboard',value:'aisplDashboard' }
      },
      {
        path: 'customers',
        component: () =>
          import ('@/views/aispl/customerList'),
        name: 'aisplCustomer',
        meta: { title: 'Aispl Customers',value:'aisplCustomer' }
      },
      {
        path: 'subscription',
        component: () =>
          import ('@/views/aispl/subscriptionList'),
        name: 'aisplSubscription',
        query:{'vendor':':vendor'},
        meta: { title: 'Aispl Subscriptions',value:'aisplSubscription' }
      },
      {
        path: 'quantity-wise-customers',
        component: () =>
        import ('@/views/aispl/quantity-wise-customers'),
        name: 'aisplQuantityWiseCustomers',
        meta: { title: 'Quantity Wise Customers' }
      },
      {
        path: 'quantity-wise-products',
        component: () =>
        import ('@/views/aispl/quantity-wise-products'),
        name: 'aisplQuantityWiseProducts',
        meta: { title: 'Quantity Wise Products' }
      }
    ]
  },
  {
    path: '/user-management',
    component: Layout,
    children: [{
        path: 'users',
        component: () =>
            import ('@/views/user-management/users'),
        name: 'usersList',
        meta: { title: 'Users' }
    }]
  },
  {
    path: '/renewals',
    component: Layout,
    children: [{
        path: '/renewals',
        component: () =>
            import ('@/views/renewals/index'),
        name: 'allRenewals',
        meta: { title: 'Renewals' }
    }]
  },
  {
    path: '/customer-with-details',
    component: Layout,
    children: [
      {
        path: 'microsoft',
        component: () =>
            import ('@/views/customerDetailsList'),
        name: 'customerDetailsList',
        meta: { title: 'CustomerDetailsList' }
      },
      {
        path: 'adobe',
        component: () =>
            import ('@/views/adobeCustomerDetailsList'),
        name: 'customerDetailsListAdobe',
        meta: { title: 'AdobeCustomerDetailsList' }
    }
  ]
  },
  {
    path: '/price-list',
    component: Layout,
    children: [{
        path: '/price-list',
        component: () =>
            import ('@/views/price-list/index'),
        name: 'priceList',
        meta: { title: 'Price List' }
    }]
  },
  {
    path: "/ncePriceList",
    component: Layout,
    redirect: "/ncePriceList",
    children: [
      {
        path: "/ncePriceList",
        component: () => import("@/views/nce/offer-list"),
        name: "ncePriceList",
        meta: { title: "NCE Price List", icon: "clipboard", noCache: true },
      },
    ],
  },
  {
    path: "/perpetualPriceList",
    component: Layout,
    redirect: "/perpetualPriceList",
    children: [
      {
        path: "/perpetualPriceList",
        component: () => import("@/views/nce/perpetualPriceList"),
        name: "perpetualPriceList",
        meta: {
          title: "Perpetual Price List",
          icon: "clipboard",
          noCache: true,
        },
      },
    ],
  },
  {
    path: "/softwarePriceList",
    component: Layout,
    redirect: "/softwarePriceList",
    children: [
      {
        path: "/softwarePriceList",
        component: () => import("@/views/nce/softwarePriceList"),
        name: "softwarePriceList",
        meta: {
          title: "Software Price List",
          icon: "clipboard",
          noCache: true,
        },
      },
    ],
  },
  {
    path: '/settings',
    component: Layout,
    children: [{
        path: '/settings',
        component: () =>
            import ('@/views/renewals/emailSetting'),
        name: 'settings',
        meta: { title: 'Settings' }
    }]
  },
  {
    path: '/support',
    component: Layout,
    children: [{
        path: '/ticket-create',
        component: () =>
            import ('@/views/support/ticket-create'),
        name: 'SupportTicketCreate',
        meta: { title: 'Support Ticket Create' }
    },
    {
      path: '/ticket-list',
      component: () =>
          import ('@/views/support/ticket-list'),
      name: 'SupportTicketList',
      meta: { title: 'Support Ticket List' }
    }]
  },
  {
    path: '/orders',
    component: Layout,
    children: [{
        path: '/orders',
        component: () =>
            import ('@/views/orders/index.vue'),
        name: 'orders',
        meta: { title: 'Order' }
    }]
  },
  {
    path: '/ingram-orders',
    component: Layout,
    children: [{
        path: '/ingram-orders',
        component: () =>
            import ('@/views/ingramOrders/index.vue'),
        name: 'ingramOrders',
        meta: { title: 'Ingram Orders' }
    }]
  },
  {
    path: '/ingram-invoice',
    component: Layout,
    children: [{
        path: '/ingram-invoice',
        component: () =>
            import ('@/views/ingramInvoice/index.vue'),
        name: 'ingramInvoice',
        meta: { title: 'Ingram Invoice' }
    }]
  }
]

const router = new VueRouter({
  routes: routes,
  mode: "history"
})

router.beforeEach((to, from, next) => {
  console.log(to,'to')
  if (to.name !== 'login' && Cookies.get('login_token') == undefined)
  {
    next('/login')
  }else{
    if((to.name == 'login' || to.name == null) && Cookies.get('login_token') != undefined)
    {
      next('/dashboard')
    }else{
      next()
    }
  }
})

export default router
