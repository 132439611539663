<template>
<div style="width:100%">
    <div v-if="toggleSidebar">
        <div class="mainLabel" @click="toggleChildFun()">
            <div>
                <div v-if="name != ''">
                    <router-link :class="{activeClass : isActive,disableClass: !isActive}" :to="name" style="text-decoration:none" :underline="false">
                        <div class="d-flex justify-content-between">
                            <div>
                                <b-icon :icon="icon"></b-icon>
                                <span style="padding-left:15px">{{label}}</span>
                            </div>
                            <div v-if="child">
                                <b-icon v-if="!toggleChild" icon="chevron-down" />
                                <b-icon v-else icon="chevron-up" />
                            </div>
                        </div>
                    </router-link>
                </div>
                <div v-else>
                    <div class="d-flex justify-content-between">
                        <div>
                            <b-icon :icon="icon"></b-icon>
                            <span style="padding-left:15px">{{label}}</span>
                        </div>
                        <div v-if="child">
                            <b-icon v-if="!toggleChild" icon="chevron-down" />
                            <b-icon v-else icon="chevron-up" />
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <items v-if="toggleChild" v-for="(data,index) in child" :key="index" :label="data.label" :name="data.name" :icon="data.icon" :child="data.child" :toggleSidebar="toggleSidebar" style="padding-left:15px;padding-top:15px" />
    </div>
    <div v-else>
        <div :title="label" v-if="!child">
        <router-link :to="name" :class="{activeClass : isActive,disableClass:!isActive}" style="text-decoration:none" :underline="false">
            <b-icon :icon="icon"></b-icon>
        </router-link>
        </div>

        <el-popover v-else placement="right" width="200" trigger="click">
            <div>
                <popoveritems v-for="(data,index) in child" :key="index" :label="data.label" :name="data.name" :icon="data.icon" :child="data.child" />
            </div>
            <div slot="reference">
                <b-icon :title="label" :icon="icon"></b-icon>
            </div>
        </el-popover>
         <!-- <items v-for="(data,index) in child" :key="index" :label="data.label" :name="data.name" :icon="data.icon" :child="data.child" :toggleSidebar="toggleSidebar" style="padding-left:35px;padding-top:15px" /> -->
        <!-- <el-popover placement="right" width="400" trigger="hover">
            <div slot="reference" :title="label"><b-icon :icon="icon"></b-icon></div>
        </el-popover> -->
    </div>
</div>
</template>

<script>
import popoveritems from './popoveritems'
export default {
    name: 'items',
    components:{popoveritems},
    props: {
        label: String,
        name: String,
        icon: String,
        child: Array,
        toggleSidebar: Boolean,
        orgData:Object
    },
    data() {
        return {
            toggleChild: false,
            isActive:false
        }
    },
    created() {
        if(this.$route.name == this.name)
        {
            this.isActive = true
            this.toggleChild = true
        }

        if(  this.orgData.isOpen != undefined)
        {
            this.toggleChild = true
        }
    },
    updated()
    {
    },
    methods: {
        toggleChildFun() {
            this.toggleChild = !this.toggleChild
        }
    }
}
</script>

<style scoped>
.mainLabel {
    padding-top: 15px;
    padding: 10px;
}

.mainLabel:hover {
    cursor: pointer;
    background: #dee2e6;
}
.activeClass{
    color:red;
}
.disableClass{
    color:black;
}
</style>
