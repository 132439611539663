<template>
<div>
    <div>
        <b-navbar toggleable="lg" style="color:black !important;border-bottom:1px solid black;background-color:#EBD8D8">

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>

                <!-- Right aligned nav items -->
                <b-navbar-nav style="display:flex;justify-content:space-between;width:100%;">
                   <el-button circle @click="$router.go(-1)"><span class="el-icon-back" /></el-button>
                    <el-button v-if="$route.params.tid == undefined"  style="color: red; font-size: 1rem">{{ $route.meta.title }} {{company_name=''}}</el-button>
                    <el-button v-else  style="color: red; font-size: 1rem">{{ company_name }}
                </el-button>
                    <el-dropdown class="mt-2">
                        <span class="el-dropdown-link" style="color:red">
                            {{$Cookies.get('user_name')}}<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>Profile</el-dropdown-item>
                            <el-dropdown-item @click.native="logouts()">Logout</el-dropdown-item>

                        </el-dropdown-menu>
                    </el-dropdown>

                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</div>
</template>

<script>
import sidebar from '@/sidebar/index'
import {bus} from '@/eventbus/eventbus'
export default {
    components: {
        sidebar,bus
    },
    data() {
        return {
            user: {},
            screenLength: window.screen.width,
            company_name:""
        }
    },
    created() {
        bus.$on('navTitleName',(data)=>{
            this.company_name = data
        })
    },
    methods: {
        getUserInfo() {
            this.axios.get('/getUser')
                .then((res) => {
                    this.user = res.data.data
                })
                .catch((res) => {

                })
        },
        logouts() {
            this.$Cookies.remove('user_email')
            this.$Cookies.remove('user_name')
            this.$Cookies.remove('login_token')
            localStorage.removeItem('token')
            this.$router.push('/login')
            this.axios.delete('/user/logout')
                .then((res) => {})
                .catch((res) => {

                })
        },
    }
}
</script>

<style>
</style>
