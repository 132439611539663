<template>
    <div>
        <div id="sidebar" :class="{fullWidth:toggleSidebar,shortWidth:!toggleSidebar}">
        {{titles}}
            <b-icon style="float:right;cursor:pointer;" icon="arrows-collapse" @click="toggleSidebarFunc" />
            <items v-for="(data,index) in menuItems" :key="index"
                v-if="showSidebar" 
                :label="data.label"
                :name="data.name"
                :icon="data.icon"
                :child="data.child"
                :orgData="data"
                :toggleSidebar="toggleSidebar"
                style="padding-top:15px;padding-left:15px;"
             />
        </div>

    </div>
</template>

<script>
    import {bus} from '@/eventbus/eventbus'
    import items from './items'
    export default {
        components:{items,bus},
        data()
        {
            return {
                screenLength: window.screen.width,
                title:this.$route.name,
                showSidebar:true,
                toggleSidebar:true,
                menuItems: [
                    {
                        label:'Dashboard',
                        name:'dashboard',
                        icon:'grid3x3-gap'
                    },
                    {
                        label:'Customers',
                        name:'',
                        icon:'person-lines-fill',
                        child:[
                            {
                                label:'Adobe',
                                name:'',
                                icon:'list-stars',
                                child:[
                                    {
                                         label:'List',
                                        name:'abodeCustomers',
                                        icon:'list-stars',
                                    }
                                ]
                            },
                            {
                                label:'Ingram',
                                name:'ingramCustomers',
                                icon:'list-stars',
                            }
                        ]
                    },
                    {
                        label:'Support',
                        name:'support',
                        icon:'telephone',
                    }
                ]
            }
        },
        computed :{
            titles :function()
            {
                
                return this.activeLinkFunc()
            }
        },
        created()
        {
            if(this.screenLength < 800)
            {
                this.toggleSidebar=false
                bus.$emit('sidebarToggle',this.toggleSidebar)
            }
        },
        beforeMount()
        {
            for(let i=0; i<this.menuItems.length; i++)
            {   
                if(this.menuItems[i].child && this.menuItems[i].child.length > 0)
                {
                    // console.log(this.menuItems[i].child,'this.menuItems[i].child')
                   let res = this.checkActiveItems(this.menuItems[i].child)
                   console.log(res,'res')
                   if(res)
                   {
                        this.menuItems[i].isOpen = true
                        for(let j=0; j < this.menuItems[i].child.length; j++)
                        {
                            this.menuItems[i].child[j].isOpen = true
                            if(this.menuItems[i].child[j].name != this.$route.name)
                            {

                            }
                        }
                        // this.markActiveItems(this.menuItems[i].child,)
                   }
                }
            }

            console.log(this.menuItems,'this.menuItems')
        },
        methods:{
            markActiveItems()
            {

            },
            toggleSidebarFunc()
            {
                this.toggleSidebar = !this.toggleSidebar
                bus.$emit('sidebarToggle',this.toggleSidebar)
                this.showSidebar = false
                this.$nextTick(()=>{
                    this.showSidebar = true        
                })
            },
            activeLinkFunc()
            {
                this.showSidebar = false
                this.$nextTick(()=>{
                    this.showSidebar = true        
                })
                return this.$route.name
                // console.log(this.$route.name,'this.$route.params')
            },
            checkActiveItems(data)
            {
                for(let i=0; i<data.length; i++)
                {
                    if(data[i].name == this.$route.name)
                    {
                        //  console.log(data[i].name,'data[i].name')
                        return true;
                    }else{
                        if(data[i].child && data[i].child.length > 0)
                        {
                          let res = this.checkActiveItems(data[i].child)
                          if(res)
                          {
                            return res
                          }
                        }
                    }
                }
            }
        }
    }
</script>

<style scoped>
    #sidebar {
        position:fixed;
        height:100vh;
        background:white;
        overflow:auto;
        border-right: solid 3px black
    }
    .fullWidth{
        width:220px;
    }
    .shortWidth{
        width:50px;
    }
</style>