<!-- <template>
    <div>
      <div class="layoutContainer">
        <sidebar />
        <div :style="{ paddingLeft: paddingClass + 'px', width: '100%' }">
          <navbar />
          <router-view style="height:100%" />
          <nav :style="footerStyle" class="navbar fixed-bottom">
            <div class="container-fluid">
              <div style="margin:0 auto;font-size:13px">
                <div>Copyright © {{ year }}. All rights reserved. Powered by CYBERNETIC SYSTEMS LLP.</div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </template>
<script>
import sidebar from '@/sidebar/newSidebar'
import navbar from '@/navbar/index'
import { bus } from '@/eventbus/eventbus'
import footers from './footers'

export default {
  name: 'items',
  components: {
    sidebar,
    navbar,
    bus,
    footers
  },
  data() {
    return {
      toggleChild: false,
      paddingClass: 210,
      isCollapse: true,
      year: ''
    }
  },
  computed: {
    footerStyle() {
      return {
        color: 'white',
        backgroundColor: '#304156',
        left: this.paddingClass + 'px',
        right: '0',
        bottom: '0',
        position: 'static',
        zIndex: 1030
      }
    }
  },
  created() {
    let date = new Date()
    this.year = date.getFullYear()
    bus.$on('sidebarToggle', (data) => {
      if (data) {
        this.paddingClass = 64
      } else {
        this.paddingClass = 210
      }
    })
  }
}
</script>
<style scoped>
.layoutContainer {
  display: flex;
}

.fixed-bottom {
  left: 0;
}
</style>
   -->
   <template>
    <div class="layoutContainer">
      <sidebar />
      <div class="contentContainer" :style="{ paddingLeft: paddingClass + 'px' }">
        <navbar />
        <div class="mainContent">
          <router-view style="height: 100%" />
        </div>
        <nav :style="footerStyle" class="navbar">
          <div class="container-fluid">
            <div style="margin: 0 auto; font-size: 13px;">
              <div>Copyright © {{ year }}. All rights reserved. Powered by CYBERNETIC SYSTEMS LLP.</div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </template>
  
  <script>
  import sidebar from '@/sidebar/newSidebar'
  import navbar from '@/navbar/index'
  import { bus } from '@/eventbus/eventbus'
  
  export default {
    name: 'items',
    components: {
      sidebar,
      navbar,
      bus
    },
    data() {
      return {
        toggleChild: false,
        paddingClass: 210,
        isCollapse: true,
        year: ''
      }
    },
    computed: {
      footerStyle() {
        return {
          color: 'white',
          backgroundColor: '#304156',
          left: this.paddingClass + 'px',
          right: '0',
          bottom: '0',
          position: 'static',
          zIndex: 1030
        }
      }
    },
    created() {
      let date = new Date()
      this.year = date.getFullYear()
      bus.$on('sidebarToggle', (data) => {
        if (data) {
          this.paddingClass = 64
        } else {
          this.paddingClass = 210
        }
      })
    }
  }
  </script>
  
  <style scoped>
  .layoutContainer {
    display: flex;
    min-height: 100vh;
  }
  
  .contentContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .mainContent {
    flex: 1;
  }
  
  .navbar {
    position: relative;
    bottom: 0;
    width: 100%;
  }
  
  .fixed-bottom {
    left: 0;
  }
  </style>
  