const partnerId="acdab161-5550-45ca-8753-576bc5997c2d"
const clientId = "1594dc4b-cda3-4220-82c8-b5c803d5a7c5"
const auths={
    auth: {
    'clientId':clientId,
    "authority":"https://login.microsoftonline.com/"+partnerId
    },
    cache: {
        cacheLocation: 'localStorage',
        }
}
export default auths